<template>
  <div class="appointment-list-view">
    <app-header icon="balance-scale" title="Especialidades"></app-header>

    <section>
      <div class="row">
        <div class="col-12 col-md-12 col-sm-6 text-right">
          <b-tooltip label="Nova Especialidade">
            <router-link
              :to="{
                name: 'specialties.save',
              }"
            >
              <b-button type="is-primary" icon-right="plus">NOVO</b-button>
            </router-link>
          </b-tooltip>
          &nbsp;&nbsp;&nbsp;
          <b-tooltip label="EXPORTAR XLS">
            <b-button
              @click="exportSpecialties()"
              type="is-primary"
              icon-right="file-excel"
              :disabled="isExporting"
              :loading="isExporting"
            >
              EXPORTAR XLS
            </b-button>
          </b-tooltip>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-sm-6">
          <b-table
            @page-change="onPageChange"
            :data="data"
            :paginated="isPaginated"
            :per-page="perPage"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :pagination-position="paginationPosition"
            :default-sort-direction="defaultSortDirection"
            :pagination-rounded="isPaginationRounded"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            default-sort="row.name"
            :total="total"
            :loading="isLoading"
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column width="80%" v-slot="props" field="name" label="NOME" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column width="20%" v-slot="props" label="OPÇÕES">
              <router-link
                :to="{
                  name: 'specialties.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar" class="m-1">
                  <b-button
                    type="is-primary"
                    size="is-small"
                    icon-right="edit"
                  />
                </b-tooltip>
              </router-link>

              <b-tooltip label="Deletar" class="m-1">
                <b-button
                  @click="onClickDelete(props.row.id)"
                  type="is-primary"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
            <template #footer v-if="total > 0">
              <div class="row align-middle text-center">
                <div v-if="perPage < total" class="col-5 col-md-5 col-sm-3 ptext text-right">
                  Exibindo {{ perPage }} de {{ total }}
                </div>
                <div v-if="perPage > total" class="col-5 col-md-5 col-sm-3 ptext text-right">
                  Exibindo {{ total }} de {{ total }}
                </div>
                <div class="col-auto col-md-auto col-sm-auto text-center">
                  <b-pagination v-model="currentPage" :total-rows="total" align="center" :per-page="perPage" :limit="4">
                    <span slot="page" slot-scope="{ page, active }">
                      <b v-if="active">{{ page }}</b>
                      <i v-else>{{ page }}</i>
                    </span>
                  </b-pagination>
                </div>
                <div class="col-5 col-md-5 col-sm-3 text-left">
                  <b-select class="pselect" v-model="perPage" style="margin-left: 10px;">
                    <option v-for="i in pageOptions" :key="i"><b>{{i}}</b></option>
                  </b-select>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SpecialtiesService from '@/services/specialties.service';
import debounce from 'lodash/debounce';

export default {
  data: () => ({
    isExporting: false,
    isLoading: true,
    rows: 0,
    data: [],
    total: 0,
    page: 1,
    searchName: '',
    title: '',
    perPage: 10,
    isPaginated: true,
    isPaginationSimple: false,
    isPaginationRounded: false,
    paginationPosition: 'both',
    defaultSortDirection: 'asc',
    sortIcon: 'arrow-down',
    sortIconSize: 'is-small',
    currentPage: 1,
    pageOptions: [10, 20, 40, 80],
  }),
  watch: {
    searchName: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
  },
  methods: {
    linkGen(pageNum) {
      return this.links[pageNum - 1]
    },
    pageGen(pageNum) {
      return this.links[pageNum - 1].slice(1)
    },
    load() {
      this.isLoading = true;

      SpecialtiesService.get({
        page: this.page,
        name: this.searchName,
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
          this.rows = this.data.length;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.load();
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Especialidade',
        message:
          'Tem certeza que deseja <b>excluir</b> esta especialidade? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Especialidade',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      SpecialtiesService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Especialidade excluída com sucesso.');
          this.load();
        })
        .catch(({ response }) => {
          if (typeof response != 'undefined' && response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir a Especialidade.',
              'error',
              4000
            );
          }
        });
    },
    exportSpecialties() {
      this.isExporting = true;

      let filters = {
        name: this.searchName || '',
      };

      SpecialtiesService.export({ ...filters })
        .then(({ data }) => {
          const fileName = data.split('/')[data.split('/').length - 1];
          const fileLink = document.createElement('a');

          fileLink.target = '_blank';
          fileLink.href = data;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('style', 'display:none;');

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || 'Erro ao realizar exportação.';
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss"></style>
